
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {onUpdated} from "@vue/runtime-core";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadEntity, LoadPanel, userRoleCheck} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import EntityView from "@/components/base/common/EntityView.vue";
import Swal from "sweetalert2";
import LogReportService from "@/core/services/LogReportService";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import LogReportForm from "@/views/logReport/LogReportForm.vue";
import LogReportStatus from "@/views/logReport/LogReportStatus.vue";
import LogReportType from "@/views/logReport/LogReportType.vue";

export default defineComponent({
  name: "Report",
  components: {
    LogReportType,
    LogReportStatus,
    LogReportForm,
    BaseModal,
    EntityView,
    QuickAction,
    DateTimeFormat,
    TabNavigate,
  },
  setup() {
    const entity = computed(() => store.state.LogReportModule.entity);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Report Details', [
        {link: true, router: '/log-reports', text: 'Reports'},
        {link: false, router: '', text: 'View Report'}
      ])
    })
    const state = ref({
      id: '',
      report: {schedule: {period: 'DAILY', frequencies: []}, name: '', clientIdReq: '', nextDate: '', type: ''}
    })
    return {
      state,
      ...userRoleCheck(),
      entity,
      ...LoadEntity(Actions.LOAD_LOG_REPORT),
      ...LoadPanel(),
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          LogReportService.delete(id).then(() => {
            this.$router.push({name: 'logReports'})
          })
        }
      })
    },
    onEdit(entity) {
      this.state.id = entity.id
      this.state.report = {
        schedule: entity.schedule,
        name: entity.name,
        clientIdReq: entity.client.id,
        nextDate: entity.nextDate,
        type: entity.type
      }
      const modal = this.$refs.formEditReportRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.formEditReportRef as typeof BaseModal
      modal.hideBaseModal()
      this.reloadData();
    },

    updateStatus(id, status) {
      Swal.showLoading()
      LogReportService.updateStatus(id, status).then(() => {
        this.reloadData()
      }).finally(() => {
        Swal.close();
      })
    },
    run(id) {
      Swal.showLoading()
      LogReportService.run(id).then(() => {
        this.reloadData()
      }).finally(() => {
        Swal.close();
      })
    }
  }
})
